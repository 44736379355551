import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-advanced-details',
    templateUrl: './advanced-details.component.html',
    standalone: false
})
export class AdvancedDetailsComponent {
  @ViewChild('advancedDetails') form!: NgForm;

  submitData() {
    if (this.form.valid) {
      return this.form.value;
    }
  }
}
