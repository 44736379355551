import { Component } from '@angular/core';
import { NgIcon } from '@ng-icons/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-support-button',
  templateUrl: './support-button.component.html',
  styleUrls: ['./support-button.component.css'],
  standalone: true,
  imports: [TranslateModule, NgIcon],
})
export class SupportButtonComponent {
  showContextMenu = false;

  openContextMenu() {
    this.showContextMenu = !this.showContextMenu;
  }
}
