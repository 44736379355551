import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  inject,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserPortalType } from 'src/app/shared/models/bierdeckel-api.model';
import { MandatorService } from 'src/app/shared/services/mandator.service';
import { PreLoginData } from 'src/app/shared/models/mandator.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-copyright-footer',
  templateUrl: './copyright-footer.component.html',
  styleUrl: './copyright-footer.component.css',
  standalone: true,
  imports: [CommonModule],
})
export class CopyrightFooterComponent implements OnInit {
  private mandatorService = inject(MandatorService);
  public translateService = inject(TranslateService);

  @Input({ required: true }) textColor!: string;
  @Input({ required: true }) userPortalType!: UserPortalType;
  @Input() mandatorId: string = this.mandatorService.defaultMandatorId;
  @Output() preLoginDataOutput = new EventEmitter<PreLoginData>();

  currentYear: number = new Date().getFullYear();
  preLoginData?: PreLoginData;

  async ngOnInit(): Promise<void> {
    if (this.mandatorId) {
      this.mandatorService
        .getMandatorPreLoginDataDisplayItem(
          this.mandatorId,
          this.userPortalType
        )
        .subscribe({
          next: (preLoginData: PreLoginData) => {
            if (preLoginData) {
              this.preLoginDataOutput.emit(preLoginData);
              this.preLoginData = preLoginData;
            } else {
              throw new Error('preLoginData not found');
            }
          },
          error: (error) => {
            throw new Error('Error fetching pre-login data:', error);
          },
        });
    }
  }
}
