import { Inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateEditMandatorComponent } from './mandator-view/create-edit-mandator/create-edit-mandator.component';
import { CompanyAndOrderComponent } from './mandator-view/create-edit-mandator/company-and-order/company-and-order.component';
import { OrderDetailsComponent } from './mandator-view/create-edit-mandator/order-details/order-details.component';
import { ArbeitsVerzeichnisseComponent } from './mandator-view/create-edit-mandator/arbeits-verzeichnisse/arbeits-verzeichnisse.component';
import { ContactPersonComponent } from './mandator-view/create-edit-mandator/contact-person/contact-person.component';
import { DatenTransferComponent } from './mandator-view/create-edit-mandator/daten-transfer/daten-transfer.component';
import { FiscalYearComponent } from './mandator-view/create-edit-mandator/fiscal-year/fiscal-year.component';
import { AdvancedDetailsComponent } from './mandator-view/create-edit-mandator/advanced-details/advanced-details.component';
import { FormsModule } from '@angular/forms';
import { SidebarComponent } from './mandator-view/create-edit-mandator/sidebar/sidebar.component';
import { HeaderComponent } from './mandator-view/header/header.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MandatorViewComponent } from './mandator-view/mandator-view.component';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { SupportButtonComponent } from './shared/components/support-button/support-button.component';
import { ErrorpageComponent } from './shared/components/errorpage/errorpage.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LanguageService } from './shared/services/language.service';
import { Router } from '@angular/router';
import { LoadingSpinnerComponent } from './shared/components/loading-spinner/loading-spinner.component';
import { UserTypeSelectionComponent } from './user-type-selection/user-type-selection.component';
import { IdentityCheckComponent } from './user-type-selection/identity-check/identity-check.component';
import { LoginConsultantComponent } from './user-type-selection/login-consultant/login-consultant.component';
import { LoginCustomerComponent } from './user-type-selection/login-customer/login-customer.component';
import { CopyrightFooterComponent } from './shared/components/copyright-footer/copyright-footer.component';
import { NgIconsModule, provideNgIconsConfig } from '@ng-icons/core';
import { heroUsers, heroUser, heroKey } from '@ng-icons/heroicons/outline';
import { CustomerViewComponent } from './customer-view/customer-view.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/translate/', '.json');
}

export function initRouter(languageService: LanguageService, router: Router) {
  languageService.initRouter(router);
  return () => {};
}

@NgModule({
  declarations: [
    AppComponent,
    CreateEditMandatorComponent,
    CompanyAndOrderComponent,
    OrderDetailsComponent,
    ArbeitsVerzeichnisseComponent,
    ContactPersonComponent,
    DatenTransferComponent,
    FiscalYearComponent,
    AdvancedDetailsComponent,
    SidebarComponent,
    HeaderComponent,
    MandatorViewComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgIconsModule.withIcons({ heroUsers, heroUser, heroKey }),
    ErrorpageComponent,
    LoadingSpinnerComponent,
    CopyrightFooterComponent,
    SupportButtonComponent,
    IdentityCheckComponent,
    UserTypeSelectionComponent,
    LoginConsultantComponent,
    LoginCustomerComponent,
    CustomerViewComponent,
  ],
  providers: [
    LanguageService,
    {
      provide: 'initRouter',
      useFactory: initRouter,
      deps: [LanguageService, Router],
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideNgIconsConfig({
      size: '2em',
    }),
  ],
})
export class AppModule {
  constructor(@Inject('initRouter') init: () => void) {
    init();
  }
}
