import { Component, ViewChild } from '@angular/core';
import { CompanyAndOrderComponent } from './company-and-order/company-and-order.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { ArbeitsVerzeichnisseComponent } from './arbeits-verzeichnisse/arbeits-verzeichnisse.component';
import { ContactPersonComponent } from './contact-person/contact-person.component';
import { DatenTransferComponent } from './daten-transfer/daten-transfer.component';
import { FiscalYearComponent } from './fiscal-year/fiscal-year.component';
import { AdvancedDetailsComponent } from './advanced-details/advanced-details.component';
import { Mandator } from 'src/app/shared/models/mandator.model';

@Component({
    selector: 'app-create-edit-mandator',
    templateUrl: './create-edit-mandator.component.html',
    styleUrl: './create-edit-mandator.component.css',
    standalone: false
})
export class CreateEditMandatorComponent {
  @ViewChild('companyAndOrder') companyAndOrder!: CompanyAndOrderComponent;
  @ViewChild('orderDetails') orderDetails!: OrderDetailsComponent;
  // @ViewChild('arbeitsVerzeichnisse')
  // arbeitsVerzeichnisse!: ArbeitsVerzeichnisseComponent;
  @ViewChild('contactPerson') contactPerson!: ContactPersonComponent;
  // @ViewChild('datenTransfer') datenTransfer!: DatenTransferComponent;
  @ViewChild('fiscalYear') fiscalYear!: FiscalYearComponent;
  @ViewChild('advancedDetails') advancedDetails!: AdvancedDetailsComponent;

  activeComponent = 'companyAndOrder';

  switchComponent(activeTab: string) {
    this.activeComponent = activeTab;
  }

  submitForms() {
    // Access the child components and retrieve the entered data
    const companyAndOrder = this.companyAndOrder.submitData();
    const orderDetails = this.orderDetails.submitData();
    // const formData3 = this.arbeitsVerzeichnisse.submitData();
    const contactPerson = this.contactPerson.submitData();
    // const formData5 = this.datenTransfer.submitData();
    const fiscalYear = this.fiscalYear.submitData();
    const advancedDetails = this.advancedDetails.submitData();

    const mandator: Mandator[] = [
      {
        mandatorId: '1',
        editMode: false,
        status: '???',
        companyAndOrder: companyAndOrder,
        orderDetails: orderDetails,
        contactPerson: contactPerson,
        fiscalYear: fiscalYear,
        advancedDetails: advancedDetails,
      },
    ];

    // Log the form data to the console
    console.log(mandator);
  }
}
