import { Component, OnInit, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { CopyrightFooterComponent } from 'src/app/shared/components/copyright-footer/copyright-footer.component';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-identity-check',
  templateUrl: './identity-check.component.html',
  styleUrl: './identity-check.component.css',
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    RouterModule,
    CopyrightFooterComponent,
  ],
})
export class IdentityCheckComponent implements OnInit {
  private authService = inject(AuthService);
  private navigationService = inject(NavigationService);
  public router = inject(Router);
  public translateService = inject(TranslateService);

  mandatorId?: string;
  customerId?: string;

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.navigationService.redirectOnLogin();
    }
  }

  proceedToLogin() {
    if (this.mandatorId && !this.customerId) {
      localStorage.setItem(environment.lsMandatorKey, this.mandatorId);
      this.router.navigate([
        `/${this.translateService.currentLang}/consultant/${this.mandatorId}`,
      ]);
    } else if (this.mandatorId && this.customerId) {
      localStorage.setItem(environment.lsMandatorKey, this.mandatorId);
      localStorage.setItem(environment.lsCustomerKey, this.customerId);
      this.router.navigate([
        `/${this.translateService.currentLang}/customer/${this.mandatorId}/${this.customerId}`,
      ]);
    }
  }

  checkSubmit() {
    if (
      this.router.url ===
      '/' + this.translateService.currentLang + '/customer'
    ) {
      return !this.mandatorId || !this.customerId;
    } else {
      return !this.mandatorId;
    }
  }
}
