import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-errorpage',
  templateUrl: './errorpage.component.html',
  styleUrl: './errorpage.component.css',
  standalone: true,
  imports: [TranslateModule, RouterModule],
})
export class ErrorpageComponent implements OnInit {
  private route = inject(ActivatedRoute);
  public translateService = inject(TranslateService);

  error?: string;

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.error = params['errorMessage'];
    });
  }
}
