import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactPerson } from 'src/app/shared/models/mandator.model';
import { MandatorService } from 'src/app/shared/services/mandator.service';

@Component({
    selector: 'app-contact-person',
    templateUrl: './contact-person.component.html',
    styles: [
        `
      @media screen and (max-width: 576px) {
        .table-responsible {
          overflow: auto;
        }
      }
    `,
    ],
    standalone: false
})
export class ContactPersonComponent implements OnInit {
  customerId = '';
  contactPersonElements: ContactPerson[] = [];

  contactPersonId = '';
  company = '';
  salutationStyle = '';
  salutation = '';
  title = '';
  firstname = '';
  familyName = '';
  nameAddition = '';
  mail = '';
  phone = '';
  mobile = '';
  fax = '';
  companyPosition = '';
  street = '';
  postalCode = '';
  location = '';
  country = '';
  website = '';
  bdReportingRole = '';
  additionalRoleName = '';
  mainContact = false;
  additionalReportingRecipient = false;
  fibuRequest = false;
  fibuRequestCCAlways = false;
  fibuRequestCCReminder = false;
  taxConsultant = false;
  invoiceRecipient = false;
  otherRole = '';
  note = '';
  reasonDatasaving = '';
  refDate = '';
  editMode = false;
  selectedContactIndex = -1;

  constructor(
    private mandatorService: MandatorService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      if (params['id']) {
        this.customerId = params['id'];
        this.getCustomer(params['id']);
      }
    });
  }

  getCustomer(id: string) {
    console.log(id);
    // this.mandatorService.getMandator(id).subscribe({
    //   next: (response: any) => {
    //     this.contactPersonElements = response.ansprechpartner;
    //   },
    //   error: (error) => console.log(error),
    // });
  }

  addContact() {
    const newContact: ContactPerson = {
      contactPersonId: this.contactPersonId
        ? this.contactPersonId
        : String(this.contactPersonElements.length + 1),
      company: this.company,
      salutationStyle: this.salutationStyle,
      salutation: this.salutation,
      title: this.title,
      firstname: this.firstname,
      familyName: this.familyName,
      nameAddition: this.nameAddition,
      mail: this.mail,
      phone: this.phone,
      mobile: this.mobile,
      fax: this.fax,
      companyPosition: this.companyPosition,
      street: this.street,
      postalCode: this.postalCode,
      location: this.location,
      country: this.country,
      website: this.website,
      bdReportingRole: this.bdReportingRole,
      additionalRoleName: this.additionalRoleName,
      mainContact: this.mainContact,
      additionalReportingRecipient: this.additionalReportingRecipient,
      fibuRequest: this.fibuRequest,
      fibuRequestCCAlways: this.fibuRequestCCAlways,
      fibuRequestCCReminder: this.fibuRequestCCReminder,
      taxConsultant: this.taxConsultant,
      invoiceRecipient: this.invoiceRecipient,
      otherRole: this.otherRole,
      note: this.note,
      reasonDatasaving: this.reasonDatasaving,
      refDate: this.refDate,
      editMode: this.editMode ? this.editMode : false,
    };

    const existingIndex = this.contactPersonElements.findIndex(
      (contact) => contact.contactPersonId === newContact.contactPersonId
    );

    if (existingIndex !== -1) {
      // Update existing contact if the ID already exists
      this.contactPersonElements[existingIndex] = newContact;
    } else {
      // Add the new contact if the ID doesn't exist
      this.contactPersonElements.push(newContact);
    }

    this.resetForm();
  }

  resetForm() {
    this.contactPersonId = '';
    this.company = '';
    this.salutationStyle = '';
    this.salutation = '';
    this.title = '';
    this.firstname = '';
    this.familyName = '';
    this.nameAddition = '';
    this.mail = '';
    this.phone = '';
    this.mobile = '';
    this.fax = '';
    this.companyPosition = '';
    this.street = '';
    this.postalCode = '';
    this.location = '';
    this.country = '';
    this.website = '';
    this.bdReportingRole = '';
    this.additionalRoleName = '';
    this.mainContact = false;
    this.additionalReportingRecipient = false;
    this.fibuRequest = false;
    this.fibuRequestCCAlways = false;
    this.fibuRequestCCReminder = false;
    this.taxConsultant = false;
    this.invoiceRecipient = false;
    this.otherRole = '';
    this.note = '';
    this.reasonDatasaving = '';
    this.refDate = '';
    this.editMode = false;
  }

  isSelectedContact(index: number): boolean {
    return index === this.selectedContactIndex;
  }

  toggleContact(index: number, contact: any) {
    this.selectedContactIndex = index;
    contact.editMode = !contact.editMode;
  }

  isAnyContactSelected(): boolean {
    return this.contactPersonElements.some((contact) => contact.editMode);
  }

  deleteSelectedContact() {
    const selectedContact = this.contactPersonElements.filter(
      (contact) => contact.editMode
    );
    const index = this.contactPersonElements.indexOf(selectedContact[0]);
    this.contactPersonElements.splice(index, 1);
    this.selectedContactIndex = -1;
  }

  editSelectedContact() {
    const selectedContact = this.contactPersonElements.filter(
      (contact) => contact.editMode
    );
    this.contactPersonId = selectedContact[0].contactPersonId;
    this.company = selectedContact[0].company;
    this.salutationStyle = selectedContact[0].salutationStyle;
    this.salutation = selectedContact[0].salutation;
    this.title = selectedContact[0].title;
    this.firstname = selectedContact[0].firstname;
    this.familyName = selectedContact[0].familyName;
    this.nameAddition = selectedContact[0].nameAddition;
    this.mail = selectedContact[0].mail;
    this.phone = selectedContact[0].phone;
    this.mobile = selectedContact[0].mobile;
    this.fax = selectedContact[0].fax;
    this.companyPosition = selectedContact[0].companyPosition;
    this.street = selectedContact[0].street;
    this.postalCode = selectedContact[0].postalCode;
    this.location = selectedContact[0].location;
    this.country = selectedContact[0].country;
    this.website = selectedContact[0].website;
    this.bdReportingRole = selectedContact[0].bdReportingRole;
    this.additionalRoleName = selectedContact[0].additionalRoleName;
    this.mainContact = selectedContact[0].mainContact;
    this.additionalReportingRecipient =
      selectedContact[0].additionalReportingRecipient;
    this.fibuRequest = selectedContact[0].fibuRequest;
    this.fibuRequestCCAlways = selectedContact[0].fibuRequestCCAlways;
    this.fibuRequestCCReminder = selectedContact[0].fibuRequestCCReminder;
    this.taxConsultant = selectedContact[0].taxConsultant;
    this.invoiceRecipient = selectedContact[0].invoiceRecipient;
    this.otherRole = selectedContact[0].otherRole;
    this.note = selectedContact[0].note;
    this.reasonDatasaving = selectedContact[0].reasonDatasaving;
    this.refDate = selectedContact[0].refDate;
    this.editMode = selectedContact[0].editMode;
  }

  submitData() {
    if (this.customerId) {
      this.mandatorService
        .updateMandator(this.customerId, this.contactPersonElements)
        .subscribe({
          next: (response) => console.log(response),
          error: (error) => console.log(error),
        });
    }
    return this.contactPersonElements;
  }
}
