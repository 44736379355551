import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { MandatorService } from 'src/app/shared/services/mandator.service';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private router: Router,
    private translateService: TranslateService,
    private mandatorService: MandatorService,
    private customerService: CustomerService
  ) {}

  public setParams(params: Params): void {
    const mandatorId = params['mandatorId'];
    const customerId = params['customerId'];

    if (mandatorId) {
      localStorage.setItem(environment.lsMandatorKey, mandatorId);
      this.mandatorService.setMandatorId(mandatorId);
      this.customerService.setMandatorId(mandatorId);
    }
    if (customerId) {
      localStorage.setItem(environment.lsCustomerKey, customerId);
      this.mandatorService.setCustomerId(customerId);
      this.customerService.setCustomerId(customerId);
    }
  }

  public redirectOnLogout() {
    const mandatorId = localStorage.getItem(environment.lsMandatorKey);
    const customerId = localStorage.getItem(environment.lsCustomerKey);

    if (mandatorId && !customerId) {
      // redirect to consultant login-page
      this.router.navigate([
        `/${this.translateService.currentLang}/consultant/${mandatorId}`,
      ]);
    } else if (mandatorId && customerId) {
      // redirect to customer login-page
      this.router.navigate([
        `/${this.translateService.currentLang}/customer/${mandatorId}/${customerId}`,
      ]);
    } else {
      this.router.navigate([`/${this.translateService.currentLang}`]);
    }
  }

  public redirectOnLogin() {
    const mandatorId = localStorage.getItem(environment.lsMandatorKey);
    const customerId = localStorage.getItem(environment.lsCustomerKey);

    if (mandatorId && !customerId) {
      // redirect to consultant start-page
      this.router.navigate([
        `/${this.translateService.currentLang}/consultant/${mandatorId}/start`,
      ]);
    } else if (mandatorId && customerId) {
      // redirect to customer start-page
      this.router.navigate([
        `/${this.translateService.currentLang}/customer/${mandatorId}/${customerId}/start`,
      ]);
    } else {
      this.router.navigate([`/${this.translateService.currentLang}`]);
    }
  }
}
