import { Component, OnInit, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PreLoginData } from 'src/app/shared/models/mandator.model';
import { UserPortalType } from 'src/app/shared/models/bierdeckel-api.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { firstValueFrom } from 'rxjs';
import { CustomerService } from 'src/app/shared/services/customer.service';
import { FormsModule } from '@angular/forms';
import { NgIcon } from '@ng-icons/core';
import { CopyrightFooterComponent } from 'src/app/shared/components/copyright-footer/copyright-footer.component';
import { SupportButtonComponent } from 'src/app/shared/components/support-button/support-button.component';

@Component({
  selector: 'app-login-customer',
  templateUrl: './login-customer.component.html',
  styleUrls: ['./login-customer.component.css'],
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    NgIcon,
    CopyrightFooterComponent,
    SupportButtonComponent,
  ],
})
export class LoginCustomerComponent implements OnInit {
  private authService = inject(AuthService);
  private customerService = inject(CustomerService);
  private router = inject(Router);
  private navigationService = inject(NavigationService);
  private activatedRoute = inject(ActivatedRoute);
  public translateService = inject(TranslateService);

  username?: string;
  password?: string;
  mandatorId?: string;
  customerId?: string;
  preLoginData?: PreLoginData;
  machineName?: string;
  appProcessId?: number;
  userPortalType: UserPortalType = 'Consultant';
  loading: boolean = false;
  errorMessageLoginFail?: string;
  errorMessageUsername?: string;
  errorMessagePassword?: string;

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.navigationService.redirectOnLogin();
    } else {
      this.activatedRoute.params.subscribe((params) => {
        this.navigationService.setParams(params);
      });
      this.mandatorId = this.customerService.getMandatorId();
      this.customerId = this.customerService.getCustomerId();

      this.machineName = this.customerService.getBrowserAndOS();
      this.appProcessId = Math.floor(Math.random() * Math.max(1000, 1)) + 1;
    }
  }

  async onLogin() {
    this.loading = true;
    this.resetErrorMessages();

    if (!this.username || this.username.trim() === '') {
      this.translateErrorMessageUsername();
      this.loading = false;
      return;
    }
    if (!this.password || this.password.trim() === '') {
      this.translateErrorMessagePassword();
      this.loading = false;
      return;
    }

    try {
      const accessToken = await firstValueFrom(
        this.customerService.loginCustomerAPI(
          this.mandatorId!,
          this.customerId!,
          this.username,
          this.password,
          this.machineName!,
          this.appProcessId!
        )
      );

      if (accessToken) {
        localStorage.setItem(environment.lsAccessTokenKey, accessToken);
        this.router.navigate([
          `/${this.translateService.currentLang}/customer/${this.mandatorId}/${this.customerId}/start`,
        ]);
      } else {
        throw new Error('Access token not found!');
      }
    } catch (error) {
      this.translateErrorMessageLoginFail();
    } finally {
      this.loading = false;
    }
  }

  translateErrorMessageLoginFail() {
    this.translateService
      .get(['LoginConsultantAndCustomerComponent.errorMessageLoginFail'])
      .subscribe((translations) => {
        this.errorMessageLoginFail =
          translations[
            'LoginConsultantAndCustomerComponent.errorMessageLoginFail'
          ];
      });
  }

  translateErrorMessageUsername() {
    this.translateService
      .get(['LoginConsultantAndCustomerComponent.errorMessageUsername'])
      .subscribe((translations) => {
        this.errorMessageUsername =
          translations[
            'LoginConsultantAndCustomerComponent.errorMessageUsername'
          ];
        console.log(this.errorMessageUsername);
      });
  }

  translateErrorMessagePassword() {
    this.translateService
      .get(['LoginConsultantAndCustomerComponent.errorMessagePassword'])
      .subscribe((translations) => {
        this.errorMessagePassword =
          translations[
            'LoginConsultantAndCustomerComponent.errorMessagePassword'
          ];
      });
  }

  resetErrorMessages() {
    this.errorMessageLoginFail = undefined;
    this.errorMessageUsername = undefined;
    this.errorMessagePassword = undefined;
  }
}
