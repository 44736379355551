import { Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'app-user-type-selection',
  templateUrl: './user-type-selection.component.html',
  styleUrl: './user-type-selection.component.css',
  standalone: true,
  imports: [RouterModule, TranslateModule],
})
export class UserTypeSelectionComponent implements OnInit {
  private navigationService = inject(NavigationService);
  public authService = inject(AuthService);
  public translateService = inject(TranslateService);

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.navigationService.redirectOnLogin();
    }
  }
}
