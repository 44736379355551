import { Component } from '@angular/core';

@Component({
    selector: 'app-daten-transfer',
    templateUrl: './daten-transfer.component.html',
    styleUrls: ['./daten-transfer.component.css'],
    standalone: false
})
export class DatenTransferComponent {

}
