import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MandatorService } from 'src/app/shared/services/mandator.service';
import { ListElement } from 'src/app/shared/models/mandator.model';

@Component({
    selector: 'app-order-details',
    templateUrl: './order-details.component.html',
    standalone: false
})
export class OrderDetailsComponent implements OnInit {
  @ViewChild('orderDetails') form!: NgForm;

  customerId = '';
  orderFromMonth = '';
  orderFromYear = '';
  orderToMonth = '';
  orderToYear = '';
  orderStop = false;
  orderRhythm = '';
  chartOfAccounts = '';
  additionalAccountDigits = '';
  chartOfAccountsRule = '';
  previousYearStructure = false;
  accountingType = '';
  accountingSystem = '';
  accountingSystemInfo = '';
  internalNote = '';

  // list elements
  monthElements: ListElement[] = [];
  rhythmElements: ListElement[] = [];
  chartOfAccountElements: ListElement[] = [];
  additionalChartCharElements: ListElement[] = [];
  accountingTypeElements: ListElement[] = [];
  accountingSystemElements: ListElement[] = [];
  yearElements: ListElement[] = [];

  constructor(
    private mandatorService: MandatorService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.translateMonthLabels();
    this.translateRhythmLabels();
    this.translateChartOfAccountLabels();
    this.translateAccountingTypeLabels();
    this.translateAdditionalChartCharLabels();
    this.translateAccountingSystemLabels();

    // dynamically generate years based on current year
    const currentYear = new Date().getFullYear();
    for (let i = currentYear - 2; i <= currentYear + 2; i++) {
      this.yearElements.push({ label: i.toString(), value: i.toString() });
    }

    this.activatedRoute.params.subscribe((params) => {
      if (params['id']) {
        this.customerId = params['id'];
        this.getCustomer(params['id']);
      }
    });
  }

  getCustomer(id: string) {
    console.log(id);
    // this.mandatorService.getMandator(id).subscribe({
    //   next: (response: any) => {
    //     this.orderFromMonth = response.orderDetails.auftragAbMonat;
    //     this.orderFromYear = response.orderDetails.auftragAbJahr;
    //     this.orderToMonth = response.orderDetails.auftragBisMonat;
    //     this.orderToYear = response.orderDetails.auftragBisJahr;
    //     this.orderStop = response.orderDetails.auftragStop;
    //     this.orderRhythm = response.orderDetails.auftragRhythmus;
    //     this.chartOfAccounts = response.orderDetails.kontenrahmen;
    //     this.additionalAccountDigits =
    //       response.orderDetails.zusatzZiffernKonten;
    //     this.chartOfAccountsRule = response.orderDetails.kontenrahmenRegel;
    //     this.previousYearStructure = response.orderDetails.vorjahresStruktur;
    //     this.accountingType = response.orderDetails.bilanzierungsArt;
    //     this.accountingSystem = response.orderDetails.buchhaltungsSystem;
    //     this.accountingSystemInfo =
    //       response.orderDetails.buchhaltungsSystemInfo;
    //     this.internalNote = response.orderDetails.notiz;
    //   },
    //   error: (error) => console.log(error),
    // });
  }

  translateMonthLabels() {
    this.translateService
      .get([
        'OrderDetailsComponent.months.january',
        'OrderDetailsComponent.months.february',
        'OrderDetailsComponent.months.march',
        'OrderDetailsComponent.months.April',
        'OrderDetailsComponent.months.may',
        'OrderDetailsComponent.months.june',
        'OrderDetailsComponent.months.july',
        'OrderDetailsComponent.months.august',
        'OrderDetailsComponent.months.september',
        'OrderDetailsComponent.months.october',
        'OrderDetailsComponent.months.november',
        'OrderDetailsComponent.months.december',
      ])
      .subscribe((translations) => {
        this.monthElements = [
          {
            label: translations['OrderDetailsComponent.months.january'],
            value: 'jan',
          },
          {
            label: translations['OrderDetailsComponent.months.february'],
            value: 'feb',
          },
          {
            label: translations['OrderDetailsComponent.months.march'],
            value: 'mar',
          },
          {
            label: translations['OrderDetailsComponent.months.April'],
            value: 'apr',
          },
          {
            label: translations['OrderDetailsComponent.months.may'],
            value: 'may',
          },
          {
            label: translations['OrderDetailsComponent.months.june'],
            value: 'jun',
          },
          {
            label: translations['OrderDetailsComponent.months.july'],
            value: 'jul',
          },
          {
            label: translations['OrderDetailsComponent.months.august'],
            value: 'aug',
          },
          {
            label: translations['OrderDetailsComponent.months.september'],
            value: 'sep',
          },
          {
            label: translations['OrderDetailsComponent.months.october'],
            value: 'oct',
          },
          {
            label: translations['OrderDetailsComponent.months.november'],
            value: 'nov',
          },
          {
            label: translations['OrderDetailsComponent.months.december'],
            value: 'dec',
          },
        ];
      });
  }

  translateRhythmLabels() {
    this.translateService
      .get([
        'OrderDetailsComponent.rhythms.monthly',
        'OrderDetailsComponent.rhythms.everyTwoMonths',
        'OrderDetailsComponent.rhythms.quarterly',
        'OrderDetailsComponent.rhythms.semiAnnually',
        'OrderDetailsComponent.rhythms.annually',
      ])
      .subscribe((translations) => {
        this.rhythmElements = [
          {
            label: translations['OrderDetailsComponent.rhythms.monthly'],
            value: 'monthly',
          },
          {
            label: translations['OrderDetailsComponent.rhythms.everyTwoMonths'],
            value: 'everyTwoMonths',
          },
          {
            label: translations['OrderDetailsComponent.rhythms.quarterly'],
            value: 'quarterly',
          },
          {
            label: translations['OrderDetailsComponent.rhythms.semiAnnually'],
            value: 'semiAnnually',
          },
          {
            label: translations['OrderDetailsComponent.rhythms.annually'],
            value: 'annually',
          },
        ];
      });
  }

  translateChartOfAccountLabels() {
    this.translateService
      .get([
        'OrderDetailsComponent.chartOfAccounts.SKR03_class8',
        'OrderDetailsComponent.chartOfAccounts.SKR04_class4',
        'OrderDetailsComponent.chartOfAccounts.SKR03_class8_noCheck',
        'OrderDetailsComponent.chartOfAccounts.SKR04_class4_noCheck',
        'OrderDetailsComponent.chartOfAccounts.customChartOfAccount_noCheck',
      ])
      .subscribe((translations) => {
        this.chartOfAccountElements = [
          {
            label:
              translations[
                'OrderDetailsComponent.chartOfAccounts.SKR03_class8'
              ],
            value: 'SKR03_class8',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.chartOfAccounts.SKR04_class4'
              ],
            value: 'SKR04_class4',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.chartOfAccounts.SKR03_class8_noCheck'
              ],
            value: 'SKR03_class8_noCheck',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.chartOfAccounts.SKR04_class4_noCheck'
              ],
            value: 'SKR04_class4_noCheck',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.chartOfAccounts.customChartOfAccount_noCheck'
              ],
            value: 'customChartOfAccount_noCheck',
          },
        ];
      });
  }

  translateAdditionalChartCharLabels() {
    this.translateService
      .get([
        'OrderDetailsComponent.additionalChartChars.xxxx',
        'OrderDetailsComponent.additionalChartChars.xxxx-0',
        'OrderDetailsComponent.additionalChartChars.xxxx-00',
        'OrderDetailsComponent.additionalChartChars.xxxx-000',
        'OrderDetailsComponent.additionalChartChars.xxxx-0000',
        'OrderDetailsComponent.additionalChartChars.xxxx-00000',
      ])
      .subscribe((translations) => {
        this.additionalChartCharElements = [
          {
            label:
              translations['OrderDetailsComponent.additionalChartChars.xxxx'],
            value: 'xxxx',
          },
          {
            label:
              translations['OrderDetailsComponent.additionalChartChars.xxxx-0'],
            value: 'xxxx-0',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.additionalChartChars.xxxx-00'
              ],
            value: 'xxxx-00',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.additionalChartChars.xxxx-000'
              ],
            value: 'xxxx-000',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.additionalChartChars.xxxx-0000'
              ],
            value: 'xxxx-0000',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.additionalChartChars.xxxx-00000'
              ],
            value: 'xxxx-00000',
          },
        ];
      });
  }

  translateAccountingTypeLabels() {
    this.translateService
      .get([
        'OrderDetailsComponent.accountingTypes.incomeStatement',
        'OrderDetailsComponent.accountingTypes.commercialBalance',
        'OrderDetailsComponent.accountingTypes.taxBalance',
      ])
      .subscribe((translations) => {
        this.accountingTypeElements = [
          {
            label:
              translations[
                'OrderDetailsComponent.accountingTypes.incomeStatement'
              ],
            value: 'incomeStatement',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingTypes.commercialBalance'
              ],
            value: 'commercialBalance',
          },
          {
            label:
              translations['OrderDetailsComponent.accountingTypes.taxBalance'],
            value: 'taxBalance',
          },
        ];
      });
  }
  translateAccountingSystemLabels() {
    this.translateService
      .get([
        'OrderDetailsComponent.accountingSystems.other_PDF',
        'OrderDetailsComponent.accountingSystems.other_PDF_ext',
        'OrderDetailsComponent.accountingSystems.DATEV_SuSa_Excel',
        'OrderDetailsComponent.accountingSystems.DATEV_SuSa_PDF',
        'OrderDetailsComponent.accountingSystems.ADDISON_SuSa_CSV',
        'OrderDetailsComponent.accountingSystems.ADDISON_SuSa_PDF',
        'OrderDetailsComponent.accountingSystems.AGENDA_SuSa_CSV_no_year',
        'OrderDetailsComponent.accountingSystems.AGENDA_SuSa_CSV',
        'OrderDetailsComponent.accountingSystems.SIMBA_SuSa_Excel',
        'OrderDetailsComponent.accountingSystems.SIMBA_SuSa_PDF',
        'OrderDetailsComponent.accountingSystems.Manuell_SuSa_Excel',
        'OrderDetailsComponent.accountingSystems.Manuell_SuSa_Excel_int',
        'OrderDetailsComponent.accountingSystems.Lexware_Online',
        'OrderDetailsComponent.accountingSystems.Manuell_SuSa_Excel_XLSX_or_CSV',
        'OrderDetailsComponent.accountingSystems.Manuell_SuSa_Excel_XLSX_or_CSV_int',
        'OrderDetailsComponent.accountingSystems.Lexware_CSV',
        'OrderDetailsComponent.accountingSystems.Lexware_Professional_SuSa_PDF',
        'OrderDetailsComponent.accountingSystems.tse_nit_AKTE_SuSa_Excel',
        'OrderDetailsComponent.accountingSystems.edrewe_SuSa_CSV',
      ])
      .subscribe((translations) => {
        this.accountingSystemElements = [
          {
            label:
              translations['OrderDetailsComponent.accountingSystems.other_PDF'],
            value: 'Sonstige_PDF',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.other_PDF_ext'
              ],
            value: 'Sonstige_PDF_ext',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.DATEV_SuSa_Excel'
              ],
            value: 'DATEV_SuSa_Excel',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.DATEV_SuSa_PDF'
              ],
            value: 'DATEV_SuSa_PDF',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.ADDISON_SuSa_CSV'
              ],
            value: 'ADDISON_SuSa_CSV',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.ADDISON_SuSa_PDF'
              ],
            value: 'ADDISON_SuSa_PDF',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.AGENDA_SuSa_CSV_no_year'
              ],
            value: 'AGENDA_SuSa_CSV_no_year',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.AGENDA_SuSa_CSV'
              ],
            value: 'AGENDA_SuSa_CSV',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.SIMBA_SuSa_Excel'
              ],
            value: 'SIMBA_SuSa_Excel',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.SIMBA_SuSa_PDF'
              ],
            value: 'SIMBA_SuSa_PDF',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.Manuell_SuSa_Excel'
              ],
            value: 'Manuell_SuSa_Excel',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.Manuell_SuSa_Excel_int'
              ],
            value: 'Manuell_SuSa_Excel_int',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.Lexware_Online'
              ],
            value: 'Lexware_Online',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.Manuell_SuSa_Excel_XLSX_or_CSV'
              ],
            value: 'Manuell_SuSa_Excel_XLSX_or_CSV',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.Manuell_SuSa_Excel_XLSX_or_CSV_int'
              ],
            value: 'Manuell_SuSa_Excel_XLSX_or_CSV_int',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.Lexware_CSV'
              ],
            value: 'Lexware_CSV',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.Lexware_Professional_SuSa_PDF'
              ],
            value: 'Lexware_Professional_SuSa_PDF',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.tse_nit_AKTE_SuSa_Excel'
              ],
            value: 'tse_nit_AKTE_SuSa_Excel',
          },
          {
            label:
              translations[
                'OrderDetailsComponent.accountingSystems.edrewe_SuSa_CSV'
              ],
            value: 'edrewe_SuSa_CSV',
          },
        ];
      });
  }

  submitData() {
    if (this.form.valid) {
      if (this.customerId) {
        this.mandatorService
          .updateMandator(this.customerId, this.form.value)
          .subscribe({
            next: (response) => console.log(response),
            error: (error) => console.log(error),
          });
      } else {
        return this.form.value;
      }
    }
  }
}
