import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListElement } from 'src/app/shared/models/mandator.model';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styles: [
        `
      .active {
        background-color: #0d6efd;
        color: white !important;
        border-radius: 0 0.375rem 0.375rem 0;
      }
    `,
    ],
    standalone: false
})
export class SidebarComponent implements OnInit {
  @Output() handleSwitchComponent = new EventEmitter<string>();

  sidebarCollapsed = true;
  sidebarWidth = '100%';
  hideSidebar = true;
  activeTab = 'companyAndOrder';

  tabElements: ListElement[] = [];

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.translateTabLabels();

    if (window.innerWidth > 1400) {
      this.sidebarCollapsed = false;
    } else {
      this.sidebarWidth = '75px';
      this.hideSidebar = false;
    }
  }

  translateTabLabels() {
    this.translateService
      .get([
        'SidebarComponent.tabElements.companyAndOrder',
        'SidebarComponent.tabElements.orderDetails',
        // 'SidebarComponent.tabElements.Arbeits_Verzeichnisse',
        'SidebarComponent.tabElements.contactPerson',
        // 'SidebarComponent.tabElements.Daten_Transfer_In_Out',
        'SidebarComponent.tabElements.fiscalYear',
        'SidebarComponent.tabElements.advancedDetails',
      ])
      .subscribe((translations) => {
        this.tabElements = [
          {
            label: translations['SidebarComponent.tabElements.companyAndOrder'],
            value: 'companyAndOrder',
          },
          {
            label: translations['SidebarComponent.tabElements.orderDetails'],
            value: 'orderDetails',
          },
          // { label: translations['SidebarComponent.tabElements.Arbeits_Verzeichnisse'], value: 'arbeits-verzeichnisse' },
          {
            label: translations['SidebarComponent.tabElements.contactPerson'],
            value: 'contactPerson',
          },
          // { label: translations['SidebarComponent.tabElements.Daten_Transfer_In_Out'], value: 'daten-transfer' },
          {
            label: translations['SidebarComponent.tabElements.fiscalYear'],
            value: 'fiscalYear',
          },
          {
            label: translations['SidebarComponent.tabElements.advancedDetails'],
            value: 'advancedDetails',
          },
        ];
      });
  }

  selectTab(tabValue: string) {
    this.activeTab = tabValue;
    this.handleSwitchComponent.emit(tabValue);
  }

  toggleSidebar() {
    this.sidebarCollapsed = !this.sidebarCollapsed;
  }
}
